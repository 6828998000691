// React
import React, { useState, useCallback } from "react";
// Third party
import { useNavigate } from "react-router-dom";
// Components
import { Columns } from "../../components/Columns/Columns";
import Table from "../Fundamental/components/MnaFundamentalTable";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
// Services
import { fetchMnaListService } from "./services/mnaListService";
// Types
import { HandleMenuProps } from "../../interfaces/pages/variedInterfaces";
// Images
import mnaIcon from "../../assets/svg/blue-mna.svg";

const MnaList: React.FC<HandleMenuProps> = ({ isMenuOpen, setIsMenuOpen }) => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const columnsInstance = new Columns();

  const fetchMnaList = useCallback(
    async (pageIndex: number, pageSize: number, sortBy: any) => {
      return await fetchMnaListService(
        pageIndex,
        pageSize,
        sortBy,
        search,
        setLoading
      );
    },
    [search]
  );

  return (
    <React.Fragment>
      <div
        className={`py-4  flex flex-col gap-[16px] ${
          isMenuOpen ? "pl-[282px]" : "pl-[60px]"
        } duration-500`}
      >
        <div className="">
        <Breadcrumbs
          breadcrumbs={["MNAs"]}
          icon={mnaIcon}
          addItem={() => navigate("/add-mna")}
        />
        </div>
        <Table
        isMenuOpen={isMenuOpen}
          typeForPagination="setMnaListPageIndex"
          columns={columnsInstance.MnaList(navigate)}
          fetchData={fetchMnaList}
          search={search}
          loading={loading}
        />
      </div>
    </React.Fragment>
  );
};

export default MnaList;
