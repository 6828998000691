// Updated NotesList.tsx
// React
import React, { useState, useCallback } from "react";
// Third party
import { useNavigate } from "react-router-dom";
// Components
import { Columns } from "../../components/Columns/Columns";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Table from "../../components/Tables/NoteTable";
import Loader from "../../components/Loader";
import ContentModal from "./components/ContentModal";
// OpenAPI
import { expiredTokenValidation } from "../../api/expiredTokenValidation";
// Images
import NoteIcon from "../../assets/svg/blue-note.svg";
// Types
import { HandleMenuProps } from "../../interfaces/pages/variedInterfaces";
// Services
import { NoteServices } from "./services/noteServices";

const NotesList: React.FC<HandleMenuProps> = ({ isMenuOpen }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [sort, setSort] = useState<any>("datePublished");
  const [content, setContent] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<any>({
    label: "Date Published",
    value: "datePublished",
  });

  const options = [
    { label: "Date Published", value: "datePublished" },
    { label: "Date Created", value: "dateCreated" },
    { label: "Date Modified", value: "dateModified" },
  ];

  const handleItemClick = (option: any) => {
    setSelectedOption(option);
    setSort(option.value);
  };

  const columnsInstance = new Columns();

  const fetchNoteList = useCallback(
    async (pageIndex: number, pageSize: number) => {
      setLoading(true);
      try {
        const sortBy = [
          {
            id: sort,
            desc: true,
          },
        ] as any;

        return await NoteServices(search, pageIndex, pageSize, sortBy);
      } catch (error) {
        expiredTokenValidation(error);
      } finally {
        setLoading(false);
      }
    },
    [sort, search]
  );

  const openModal = (content: string) => {
    setContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <React.Fragment>
      {loading && <Loader loading={loading} />}
      <div
        className={`py-2.5 pr-2.5 bg-transparent ${
          isMenuOpen ? "pl-[304px]" : "pl-[78px]"
        } duration-500 pr-4`}
      >
        <div className="-mx-4 ">
        <Breadcrumbs
          breadcrumbs={["Notes"]}
          icon={NoteIcon}
          addItem={() => navigate("/notes/add")}
          isSort={true}
          options={options}
          selectedOption={selectedOption}
          handleItemClick={handleItemClick}
        />
        </div>
        <div className="mt-4">
          <Table
            typeForPagination="setNotesListPageIndex"
            columns={columnsInstance.NotesList(navigate)}
            fetchData={fetchNoteList}
            additionalDataKey="content"
            openModal={openModal}
            loading={loading}
          />
        </div>
      </div>
      <ContentModal
        isOpen={isModalOpen}
        content={content}
        onClose={closeModal}
      />
    </React.Fragment>
  );
};

export default NotesList;
