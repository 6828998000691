// Tihrd party
import { CellProps } from "react-table";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
// Types
import { Data } from "../../interfaces/pages/Columns";
// Images
import Action from "../../assets/svg/actions.svg";
import Loader from "../Loader";

type NavigateFunction = ReturnType<typeof useNavigate>;


export class Columns {
  OldMnaDetails(): any {
    return [
      // Define your columns here
      {
        Header: "Date Published",
        accessor: "datePublished",
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#000817] dark:text-white text-sm font-medium text-left">
              {moment(row.original?.datePublished).format("MM/DD/YYYY")}
            </p>
          );
        },
      },
      {
        Header: "Source Type",
        accessor: "sourceType",
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <button className="bg-[#7B7B7B] text-[#FFF] text-sm w-full font-normal p-1 px-4 rounded-full">
              {row.original?.sourceType}
            </button>
          );
        },
      },
      {
        Header: "Source",
        accessor: "sourceDetail",
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#000817] dark:text-white text-sm font-medium text-left truncate">
              {row.original.sourceDetail}
            </p>
          );
        },
      },
      {
        Header: "Content",
        accessor: "content",
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p
              className=" text-[#000817] dark:text-white   text-sm font-medium text-left truncate"
              dangerouslySetInnerHTML={{
                __html: row.original.content,
              }}
            ></p>
          );
        },
      },
      {
        Header: "Sentiment",
        accessor: "sentiment",
        align: "right",
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#000817] dark:text-white text-sm font-medium text-right">
              {row.original.sentiment}
            </p>
          );
        },
      },
    ];
  }
  NotesListDetail(): any {
    return [
      // Define your columns here
      {
        Header: "Date Published",
        accessor: "datePublished",
        width: 20,
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p>
              {moment(row.original?.datePublished).format("MM/DD/YYYY hh:mm A")}
            </p>
          );
        },
      },
      {
        Header: "Title",
        accessor: "title",
        width: 20,
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <Link to={`/notes/${row.original.id}`}>
              <p className=" text-[#696868] dark:text-white text-sm break-words">
                {row.original?.title}
              </p>
            </Link>
          );
        },
      },
      {
        Header: "Source",
        accessor: "sourceDetail",
        width: 20,
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#696868] dark:text-white font-normal text-sm">
              {row.original?.sourceDetail}
            </p>
          );
        },
      },
      {
        Header: "Source Type",
        accessor: "sourceType",
        align: "center",
        width: 20,
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <div className="">
              <p className="text-[#696868] dark:text-white font-normal text-sm">
                {row.original?.sourceType}
              </p>
            </div>
          );
        },
      },
      {
        Header: "Author",
        accessor: "author1",
        width: 20,
        align: "center",
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#696868] dark:text-white font-normal text-sm">
              {row.original?.author1}
            </p>
          );
        },
      },
    ];
  }
  NotesList(navigate: NavigateFunction): any {
    return [
      // Define your columns here
      {
        Header: "Title",
        accessor: "title",
        width: 16.68,
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <Link
              className=" text-[#696868] dark:text-white font-bold text-sm"
              to={`/notes/${row.original?.id}`}
            >
              {row.original?.title}
            </Link>
          );
        },
      },
      {
        Header: "Source",
        accessor: "sourceDetail",
        width: 16.66,
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#696868] dark:text-white font-normal text-sm">
              {row.original?.sourceDetail}
            </p>
          );
        },
      },
      {
        Header: "Source Type",
        accessor: "sourceType",
        align: "center",
        width: 16.66,
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <div className="flex items-center justify-center">
              <p className="text-[#fff] font-normal text-sm bg-[#7B7B7B] rounded-full py-2 px-[35px] text-center w-[175px]">
                {row.original?.sourceType}
              </p>
            </div>
          );
        },
      },
      {
        Header: "Author",
        accessor: "author1",
        width: 16.66,
        align: "center",
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#696868] dark:text-white font-normal text-sm">
              {row.original?.author1}
            </p>
          );
        },
      },
      {
        Header: "Date Published",
        accessor: "datePublished",
        width: 16.66,
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p>
              {moment(row.original?.datePublished).format("MM/DD/YYYY hh:mm A")}
            </p>
          );
        },
      },
      {
        Header: "Action",
        accessor: "id",
        width: 16.66,
        align: "right",
        Cell: ({ row }: CellProps<Data>) => (
          <button
            className="text-secondary font-semibold text-sm capitalize underline float-right"
            onClick={() => navigate(`/notes/${row.original.id}/edit`)}
          >
            <div className=" rounded-full  p-3 border border-[#E6E6F9] bg-button-light cursor-pointer dark:bg-button-dark ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                className="dark:brightness-[3] brightness-0"
              >
                <g clipPath="url(#clip0_1000_6286)">
                  <path
                    d="M9.88081 2.68738L1.07744 11.4914C1.03311 11.5359 1.00152 11.5914 0.985954 11.6522L0.010151 15.5688C-0.00420054 15.6269 -0.00332103 15.6877 0.0127046 15.7454C0.0287303 15.8031 0.0593605 15.8557 0.101637 15.8981C0.166572 15.9628 0.254492 15.9992 0.346172 15.9992C0.374454 15.9992 0.402627 15.9957 0.430055 15.9888L4.34662 15.0129C4.40752 14.9976 4.46311 14.966 4.50743 14.9216L13.3116 6.11812L9.88081 2.68738ZM15.4926 1.48695L14.5126 0.507024C13.8577 -0.14793 12.7162 -0.14728 12.0619 0.507024L10.8616 1.70745L14.2922 5.13806L15.4926 3.93767C15.8197 3.61065 16 3.17534 16 2.71239C16 2.24944 15.8197 1.81414 15.4926 1.48695Z"
                    fill="#4B49AC"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1000_6286">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </button>
        ),
      },
    ];
  }
  MnaList(navigate: NavigateFunction): any {
    return [
      // Define your columns here
      {
        Header: "MNA Name",
        accessor: "name",
        width: 18,

        Cell: ({ row }: CellProps<Data>) => {
          return (
            <Link
              className="text-[#191919] font-bold text-sm capitalize   truncate dark:text-white"
              to={`/mna/${row.original?.id}`}
            >
              {row.original?.name}
            </Link>
          );
        },
      },
      {
        Header: "Category",
        accessor: "categoryName",
        width: 9,
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#191919] dark:text-white  truncate   font-normal text-sm">
              {row.original?.categoryName}
            </p>
          );
        },
      },
      {
        Header: "Acquirer",
        accessor: "acquirer",
        width: 26.5,
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#191919] dark:text-white  truncate font-normal text-sm">
              {row.original?.acquirer}
            </p>
          );
        },
      },
      {
        Header: "Target",
        accessor: "target",
        width: 21.5,
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#191919] dark:text-white  truncate font-normal text-sm">
              {row.original?.target}
            </p>
          );
        },
      },
      {
        Header: "Date Announced",
        accessor: "dateAnnounced",
        width: 12,
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#191919] dark:text-white truncate font-normal text-sm">
              {row.original?.dateAnnounced === null
                ? ""
                : moment(row.original?.dateAnnounced).format("MM/DD/YYYY")}
            </p>
          );
        },
      },
      {
        Header: "Date Closing",
        accessor: "dateClosing",
        width: 9,
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-secondary dark:text-white font-normal text-sm text-start overflow-hidden overflow-ellipsis whitespace-nowrap">
              {row.original?.dateClosing === null
                ? ""
                : moment(row.original?.dateClosing).format("MM/DD/YYYY")}
            </p>
          );
        },
      },
      {
        Header: "",
        accessor: "id",
        width: 3,
        align: "right",
        Cell: ({ row }: CellProps<Data>) => (
          <button
          id={`edit${String(row.original.id)}`}
            className="text-secondary font-semibold text-sm capitalize underline float-right hidden" 
            onClick={() => navigate(`/update-mna/${row.original.id}`)}
          >
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="pen-outline">
                <path
                  id="Vector"
                  d="M17.4158 3.72035C16.1932 2.42655 14.2031 2.42655 12.9805 3.72035L4.64534 12.5459C4.56196 12.6342 4.50664 12.7381 4.46846 12.8479C4.46612 12.8553 4.45911 12.8602 4.45677 12.8677L2.79004 18.1633C2.69653 18.4595 2.76978 18.7871 2.97861 19.0074C3.12666 19.165 3.32614 19.25 3.52951 19.25C3.61211 19.25 3.69548 19.236 3.77574 19.2071L8.77593 17.4413C8.78294 17.4389 8.78762 17.4323 8.79385 17.4298C8.89827 17.3902 8.99645 17.3308 9.07982 17.2425L17.4158 8.41778C18.6392 7.12316 18.6392 5.01496 17.4158 3.72035ZM11.8639 7.23537L12.4304 7.83524C12.4289 7.83689 12.4265 7.83771 12.425 7.83936L6.86375 13.7274L6.29882 13.1292L11.8639 7.23537ZM4.76144 17.1204L5.53676 14.6565L7.08738 16.2985L4.76144 17.1204ZM8.5297 15.4924L7.96477 14.8942L13.526 9.00609C13.5276 9.00444 13.5283 9.00196 13.5299 9.00031L14.0956 9.59935L8.5297 15.4924ZM16.314 7.25105L15.1982 8.43263L12.9657 6.06865L14.0816 4.88707C14.6972 4.23522 15.6984 4.23605 16.3132 4.88707C16.9296 5.53892 16.9296 6.5992 16.314 7.25105Z"
                  fill="#6B7280"
                />
              </g>
            </svg>
          </button>
        ),
      },
    ];
  }
  MnaDetails(): any {
    return [
      // Define your columns here
      {
        Header: "Date Published",
        accessor: "datePublished",
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#000817] dark:text-white text-sm font-medium text-left">
              {moment(row.original?.datePublished).format("MM/DD/YYYY")}
            </p>
          );
        },
      },
      {
        Header: "Source Type",
        accessor: "sourceType",
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <button className="bg-[#7B7B7B] text-[#FFF] text-sm w-full font-normal p-1 px-4 rounded-full">
              {row.original?.sourceType}
            </button>
          );
        },
      },
      {
        Header: "Source",
        accessor: "sourceDetail",
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#000817] dark:text-white text-sm font-medium text-left truncate">
              {row.original.sourceDetail}
            </p>
          );
        },
      },
      {
        Header: "Content",
        accessor: "content",
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p
              className=" text-[#000817] dark:text-white text-sm font-medium text-left truncate"
              dangerouslySetInnerHTML={{
                __html: row.original.content,
              }}
            ></p>
          );
        },
      },
      {
        Header: "Sentiment",
        accessor: "sentiment",
        align: "right",
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#000817] dark:text-white text-sm font-medium text-right">
              {row.original.sentiment}
            </p>
          );
        },
      },
    ];
  }
  FundamentalList(navigate: NavigateFunction): any {
    return [
      // Define your columns here
      {
        Header: "Name",
        accessor: "name",
        width: 24,
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <Link
              className="text-secondary font-semibold text-sm capitalize truncate dark:text-white"
              to={`/fundamental/${row.original?.id}`}
            >
              {row.original?.name}
            </Link>
          );
        },
      },
      {
        Header: "Ticker",
        accessor: "ticker",
        width: 24,
      },
      {
        Header: "Sector",
        accessor: "sectorId",
        width: 24,
      },
      {
        Header: "Date Modified",
        accessor: "dateModified",
        width: 24,
        Cell: ({ row }: CellProps<Data>) => {
          return (
            
            <p className=" text-[#696868] dark:text-white font-normal text-sm text-start overflow-hidden overflow-ellipsis whitespace-nowrap">
              {moment(row.original?.dateModified).format("MM/DD/YYYY hh:mm A")}
            </p>
          );
        },
      },
      {
        Header: "",
        accessor: "id",
        width: 4,
        align: "right",
        Cell: ({ row }: CellProps<Data>) => (
          <button
          id={`edit${String(row.original.id)}`}
               className="text-secondary font-semibold text-sm capitalize underline float-right hidden" 

            onClick={() => navigate(`/update-fundamental/${row.original.id}`)}
          >
            
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="pen-outline">
                <path
                  id="Vector"
                  d="M17.4158 3.72035C16.1932 2.42655 14.2031 2.42655 12.9805 3.72035L4.64534 12.5459C4.56196 12.6342 4.50664 12.7381 4.46846 12.8479C4.46612 12.8553 4.45911 12.8602 4.45677 12.8677L2.79004 18.1633C2.69653 18.4595 2.76978 18.7871 2.97861 19.0074C3.12666 19.165 3.32614 19.25 3.52951 19.25C3.61211 19.25 3.69548 19.236 3.77574 19.2071L8.77593 17.4413C8.78294 17.4389 8.78762 17.4323 8.79385 17.4298C8.89827 17.3902 8.99645 17.3308 9.07982 17.2425L17.4158 8.41778C18.6392 7.12316 18.6392 5.01496 17.4158 3.72035ZM11.8639 7.23537L12.4304 7.83524C12.4289 7.83689 12.4265 7.83771 12.425 7.83936L6.86375 13.7274L6.29882 13.1292L11.8639 7.23537ZM4.76144 17.1204L5.53676 14.6565L7.08738 16.2985L4.76144 17.1204ZM8.5297 15.4924L7.96477 14.8942L13.526 9.00609C13.5276 9.00444 13.5283 9.00196 13.5299 9.00031L14.0956 9.59935L8.5297 15.4924ZM16.314 7.25105L15.1982 8.43263L12.9657 6.06865L14.0816 4.88707C14.6972 4.23522 15.6984 4.23605 16.3132 4.88707C16.9296 5.53892 16.9296 6.5992 16.314 7.25105Z"
                  fill="#6B7280"
                />
              </g>
            </svg>
          </button>
        ),
      },
    ];
  }
  FileList(handleFileDownload: any, downloadingFileId: any): any {
    return [
      {
        Header: "Filename",
        accessor: "filename",
        width: 20,
        Cell: ({ row }: CellProps<Data>) => {

          const isDownloading = downloadingFileId === row.original?.id;

          return (
            <button
              className={`text-secondary font-semibold text-sm dark:text-white w-full text-start overflow-hidden overflow-ellipsis whitespace-nowrap ${isDownloading ? "cursor-not-allowed opacity-70" : ""
                }`}
              onClick={() =>
                !isDownloading && handleFileDownload(row.original?.id, row.original?.filename)
              }
              disabled={isDownloading}
            >
              {isDownloading ? (
                <span className="flex items-center">
                  <Loader loading={true} /> Downloading...
                </span>
              ) : (
                row.original?.filename
              )}
            </button>
          );
        },
      },
      {
        Header: "Username",
        accessor: "username",
        width: 20,
        align: "right",
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#696868] dark:text-white   font-normal text-sm text-start">
              {row.original?.createdBy.username}
            </p>
          );
        },
      },
      {
        Header: "Size",
        accessor: "size",
        width: 20,
        align: "left",
        Cell: ({ row }: CellProps<Data>) => {
          const sizeInMB = row.original?.size
            ? (Number(row.original.size) / 1048576).toFixed(2)
            : "0";
          return (
            <p className=" text-[#696868] dark:text-white font-normal text-sm ">
              {sizeInMB} MB
            </p>
          );
        },
      },
      {
        Header: "MimeType",
        accessor: "mimeType",
        width: 20,
        align: "center",
      },
      {
        Header: "Extension",
        accessor: "extension",
        width: 20,
        align: "left",
      },
      {
        Header: "Date Created",
        accessor: "dateCreated",
        width: 20,
        align: "left",
        justifyContent: "center",
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#696868] dark:text-white font-normal text-sm text-start overflow-hidden overflow-ellipsis whitespace-nowrap">
              {row.original?.dateAnnounced === null
                ? "-"
                : moment(row.original?.dateCreated).format("MM/DD/YYYY")}
            </p>
          );
        },
      },
    ];
  }
  Dashboard(
    setDropdownOpen: any,
    dropdownOpen: string,
    trigger: any,
    dropdown: any
  ): any {
    return [
      // Define your columns here
      { Header: "Item Type", accessor: "itemType" },
      { Header: "Type", accessor: "type" },
      { Header: "Date/Time", accessor: "dateTime" },
      { Header: "User", accessor: "user" },
      {
        Header: "Action",
        accessor: "id",
        Cell: ({ row }: CellProps<Data>) => (
          <div className="relative flex flex-col">
            <button ref={trigger} onClick={() => setDropdownOpen(row.id)}>
              <img src={Action} alt="action" className="max-w-[14px] h-4" />
            </button>
            <div
              ref={dropdown}
              onFocus={() => setDropdownOpen(row.id)}
              onBlur={() => setDropdownOpen("")}
              className={`absolute -right-4 z-10 -top-5  rounded-md space-y-1 bg-[#fff] p-[10px] border border-[#D3D3E6] ${dropdownOpen === row.id ? "block" : "hidden"
                }`}
            >
              <div>
                <div>
                  <button
                    className="text-sm  text-[#393C51]  dark:text-white font-bold rounded-sm p-[10px]"
                    onClick={() => setDropdownOpen("")}
                  >
                    View
                  </button>
                </div>
                <div>
                  <button
                    className="text-sm  text-[#393C51]  dark:text-white font-bold rounded-sm p-[10px]"
                    onClick={() => setDropdownOpen("")}
                  >
                    Approve
                  </button>
                </div>
              </div>
            </div>
          </div>
        ),
      },
    ];
  }
  CompanyList(navigate: NavigateFunction): any {
    return [
      // Define your columns here
      {
        Header: "Name",
        accessor: "name",
        width: 15.18,
      },
      {
        Header: "Ticker",
        accessor: "ticker",
        width: 15.18,
      },
      {
        Header: "Sector",
        accessor: "sectorId",
        width: 15.18,
      },
      {
        Header: "Incorporation",
        accessor: "incorp",
        width: 15.18,
      },
      {
        Header: "Headquarters",
        accessor: "hq",
        width: 15.18,
      },
      {
        Header: "Geo Exposure",
        accessor: "geoExposure",
        width: 15.18,
      },
      {
        Header: "Action",
        accessor: "id",
        width: 15.18,
        align: "right",
        Cell: ({ row }: CellProps<Data>) => (
          <button
            className="text-secondary font-semibold text-sm capitalize underline float-right"
            onClick={() => navigate(`/update-company/${row.original.id}`)}
          >
            <div className=" rounded-full  p-3 border border-[#E6E6F9] bg-button-light cursor-pointer dark:bg-button-dark ">
              <svg
                className="dark:brightness-[3] brightness-0"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <g clipPath="url(#clip0_1000_6286)">
                  <path
                    d="M9.88081 2.68738L1.07744 11.4914C1.03311 11.5359 1.00152 11.5914 0.985954 11.6522L0.010151 15.5688C-0.00420054 15.6269 -0.00332103 15.6877 0.0127046 15.7454C0.0287303 15.8031 0.0593605 15.8557 0.101637 15.8981C0.166572 15.9628 0.254492 15.9992 0.346172 15.9992C0.374454 15.9992 0.402627 15.9957 0.430055 15.9888L4.34662 15.0129C4.40752 14.9976 4.46311 14.966 4.50743 14.9216L13.3116 6.11812L9.88081 2.68738ZM15.4926 1.48695L14.5126 0.507024C13.8577 -0.14793 12.7162 -0.14728 12.0619 0.507024L10.8616 1.70745L14.2922 5.13806L15.4926 3.93767C15.8197 3.61065 16 3.17534 16 2.71239C16 2.24944 15.8197 1.81414 15.4926 1.48695Z"
                    fill="#4B49AC"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1000_6286">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </button>
        ),
      },
    ];
  }
  AlertList(): any {
    return [
      // Define your columns here
      {
        accessor: "name",
        width: 25,
      },
      {
        accessor: "domainType",
        width: 25,
      },
      {
        accessor: "dateModified ",
        width: 25,
      },
      {
        accessor: "modifiedBy",
        width: 25,
      },
    ];
  }
  OldMnalist(navigate: NavigateFunction): any {
    return [
      // Define your columns here
      {
        Header: "MNA Name",
        accessor: "name",
        width: 15.18,
        Cell: ({ row }: any) => {
          return (
            <button
              className="text-secondary font-semibold text-sm capitalize underline truncate dark:text-white"
              onClick={() => navigate(`/old-mna/${row.original?.id}`)}
            >
              {row.original?.name}
            </button>
          );
        },
      },
      {
        Header: "Category",
        accessor: "categoryName",
        width: 15.18,
        Cell: ({ row }: any) => {
          return (
            <p className=" text-[#696868] font-normal text-sm dark:text-white  ">
              {row.original?.categoryName}
            </p>
          );
        },
      },
      {
        Header: "Acquirer",
        accessor: "acquirer",
        width: 15.18,
        Cell: ({ row }: any) => {
          return (
            <p className=" text-[#696868] font-normal text-sm dark:text-white  ">
              {row.original?.acquirer}
            </p>
          );
        },
      },
      {
        Header: "Target",
        accessor: "target",
        width: 15.18,
        Cell: ({ row }: any) => {
          return (
            <p className=" text-[#696868] font-normal text-sm dark:text-white  ">
              {row.original?.target}
            </p>
          );
        },
      },
      {
        Header: "Date Announced",
        accessor: "dateAnnounced",
        width: 15.18,
        Cell: ({ row }: any) => {
          return (
            <p className=" text-[#696868] font-normal text-sm dark:text-white">
              {row.original?.dateAnnounced === null
                ? ""
                : moment(row.original?.dateAnnounced).format("MM/DD/YYYY")}
            </p>
          );
        },
      },
      {
        Header: "Date Closing",
        accessor: "dateClosing",
        width: 15.18,
        Cell: ({ row }: any) => {
          return (
            <p className=" text-[#696868] font-normal text-sm dark:text-white  ">
              {row.original?.dateClosing === null
                ? ""
                : moment(row.original?.dateClosing).format("MM/DD/YYYY")}
            </p>
          );
        },
      },
      {
        Header: "Action",
        accessor: "id",
        width: 15.18,
        align: "right",
        Cell: ({ row }: CellProps<Data>) => (
          <button
            className="text-secondary font-semibold text-sm capitalize underline float-right"
            onClick={() => navigate(`/old-update-mna/${row.original.id}`)}
          >
            <div className="rounded-full  p-3 border border-[#E6E6F9] bg-button-light dark:bg-button-dark cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                className="dark:brightness-[4] brightness-0"
              >
                <g clipPath="url(#clip0_1000_6286)">
                  <path
                    d="M9.88081 2.68738L1.07744 11.4914C1.03311 11.5359 1.00152 11.5914 0.985954 11.6522L0.010151 15.5688C-0.00420054 15.6269 -0.00332103 15.6877 0.0127046 15.7454C0.0287303 15.8031 0.0593605 15.8557 0.101637 15.8981C0.166572 15.9628 0.254492 15.9992 0.346172 15.9992C0.374454 15.9992 0.402627 15.9957 0.430055 15.9888L4.34662 15.0129C4.40752 14.9976 4.46311 14.966 4.50743 14.9216L13.3116 6.11812L9.88081 2.68738ZM15.4926 1.48695L14.5126 0.507024C13.8577 -0.14793 12.7162 -0.14728 12.0619 0.507024L10.8616 1.70745L14.2922 5.13806L15.4926 3.93767C15.8197 3.61065 16 3.17534 16 2.71239C16 2.24944 15.8197 1.81414 15.4926 1.48695Z"
                    fill="#4B49AC"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1000_6286">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </button>
        ),
      },
    ];
  }
  ErrorList(
    navigate: NavigateFunction,
    isModalOpen: boolean,
    setIsModalOpen: any,
    setDeleteID: any
  ): any {
    return [
      // Define your columns here
      {
        Header: "Id",
        accessor: "id",
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <Link
              className="text-secondary font-semibold text-sm capitalize underline truncate dark:text-white"
              to={`/error/${row.original?.id}`}
            >
              {row.original?.id}
            </Link>
          );
        },
      },
      {
        Header: "Method ",
        accessor: "requestMethod",
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#696868] dark:text-white   font-normal text-sm">
              {row.original.requestMethod}
            </p>
          );
        },
      },
      {
        Header: "URI ",
        accessor: "requestUri",
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#696868] dark:text-white font-normal text-sm text-start overflow-hidden overflow-ellipsis whitespace-nowrap">
              {row.original.requestUri}
            </p>
          );
        },
      },
      {
        Header: "User ",
        accessor: "username",
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#696868] dark:text-white font-normal text-sm text-start overflow-hidden overflow-ellipsis whitespace-nowrap">
              {row.original.username}
            </p>
          );
        },
      },
      {
        Header: "Date",
        accessor: "dateCreated",
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#696868] dark:text-white font-normal text-sm text-start overflow-hidden overflow-ellipsis whitespace-nowrap">
              {row.original?.dateCreated === null
                ? ""
                : moment(row.original?.dateCreated).format(
                  "MM/DD/YYYY hh:mm a"
                )}
            </p>
          );
        },
      },
      {
        Header: "Action",
        accessor: "action",
        align: "right",
        Cell: ({ row }: CellProps<Data>) => (
          <button
            className="text-secondary font-semibold text-sm capitalize underline float-right"
            onClick={() => {
              setDeleteID(row.original.id);
              setIsModalOpen(!isModalOpen);
            }}
            data-testid='delete-error-log'
          >
            <div className="rounded-full  p-1 border border-[#E6E6F9] bg-button-light dark:bg-button-dark cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="15"
                height="15"
                viewBox="0 0 64 64"
                className="dark:brightness-[4] brightness-0"
                fill="currentColor"
              >
                <path d="M 28 7 C 25.243 7 23 9.243 23 12 L 23 15 L 13 15 C 11.896 15 11 15.896 11 17 C 11 18.104 11.896 19 13 19 L 15.109375 19 L 16.792969 49.332031 C 16.970969 52.510031 19.600203 55 22.783203 55 L 41.216797 55 C 44.398797 55 47.029031 52.510031 47.207031 49.332031 L 48.890625 19 L 51 19 C 52.104 19 53 18.104 53 17 C 53 15.896 52.104 15 51 15 L 41 15 L 41 12 C 41 9.243 38.757 7 36 7 L 28 7 z M 28 11 L 36 11 C 36.552 11 37 11.449 37 12 L 37 15 L 27 15 L 27 12 C 27 11.449 27.448 11 28 11 z M 19.113281 19 L 44.886719 19 L 43.212891 49.109375 C 43.153891 50.169375 42.277797 51 41.216797 51 L 22.783203 51 C 21.723203 51 20.846109 50.170328 20.787109 49.111328 L 19.113281 19 z M 32 23.25 C 31.033 23.25 30.25 24.034 30.25 25 L 30.25 45 C 30.25 45.966 31.033 46.75 32 46.75 C 32.967 46.75 33.75 45.966 33.75 45 L 33.75 25 C 33.75 24.034 32.967 23.25 32 23.25 z M 24.642578 23.251953 C 23.677578 23.285953 22.922078 24.094547 22.955078 25.060547 L 23.652344 45.146484 C 23.685344 46.091484 24.462391 46.835938 25.400391 46.835938 C 25.421391 46.835938 25.441891 46.835938 25.462891 46.835938 C 26.427891 46.801938 27.183391 45.991391 27.150391 45.025391 L 26.453125 24.939453 C 26.419125 23.974453 25.606578 23.228953 24.642578 23.251953 z M 39.355469 23.251953 C 38.388469 23.224953 37.580875 23.974453 37.546875 24.939453 L 36.849609 45.025391 C 36.815609 45.991391 37.571109 46.801938 38.537109 46.835938 C 38.558109 46.836938 38.578609 46.835938 38.599609 46.835938 C 39.537609 46.835938 40.314656 46.091484 40.347656 45.146484 L 41.044922 25.060547 C 41.078922 24.094547 40.321469 23.285953 39.355469 23.251953 z"></path>
              </svg>
            </div>
          </button>
        ),
      },
    ];
  }
  RegulatoryList(navigate: NavigateFunction): any {
    return [
      // Define your columns here
      {
        Header: "Name",
        accessor: "name",
        width: 25,
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <Link
              className="text-secondary font-semibold text-sm capitalize  truncate dark:text-white text-start overflow-hidden overflow-ellipsis whitespace-nowrap"
              to={`/regulatory/${row.original?.id}`}
            >
              {row.original?.name}
            </Link>
          );
        },
      },
      {
        Header: "Dominion",
        accessor: "dominion",
        width: 15,
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <div className="flex items-center">
              USA &nbsp;{" "}
              <img
                src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
                alt="USA Flag"
                style={{ width: "20px", height: "12px" }}
              />{" "}
              &nbsp; NY 🏴
            </div>
          );
        },
      },
      {
        Header: "Description",
        accessor: "description",
        width: 35,
        disableSortBy: true,
      },
      {
        Header: "Date Modified",
        accessor: "dateModified",
        width: 15,
        Cell: ({ row }: CellProps<Data>) => {
          return (
            <p className=" text-[#696868] dark:text-white font-normal text-sm text-start overflow-hidden overflow-ellipsis whitespace-nowrap">
              {moment(row.original?.dateModified).format("MM/DD/YYYY hh:mm A")}
            </p>
          );
        },
      },
    ];
  }
}
