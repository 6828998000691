// React
import React, { useState, useEffect, useRef } from "react";
// Third party
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import ReactDatePicker from "react-datepicker";
// Types
import { HandleMenuProps } from "../../interfaces/pages/variedInterfaces";
// Services
import {
  fetchCategorylist,
  createMnaView,
  getFundamentalById,
  fetchFundamentalByQuery,
  fetchSectorlist,
} from "./services/MiniFormServices";
//Utils
import { convertToDateFormat } from "../../utils";
import { validationSchema } from "./utilities/MnaAddEditMiniFormUtils";
// Components
import DatePickerMna from "./MnaAddEditMiniFormComponents/DatePickerMna";
import CategorySelectMna from "./MnaAddEditMiniFormComponents/CategorySelectMna";
import InputField from "./MnaAddEditMiniFormComponents/InputFieldMna";
import SectorSelectMna from "./MnaAddEditMiniFormComponents/SectorSelectMna";
import CancelButtonMna from "./MnaAddEditMiniFormComponents/CancelButtonMna";
import SaveButtonMna from "./MnaAddEditMiniFormComponents/SaveButtonMna";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import AsyncSelect from "../../components/AsyncSelect/indexForMiniForm";
import {showAlert} from "../../components/Modal/AlertModal";
import { handleFormError } from "../../utils/errorHandling";


const initialValues = {
  fundamentalSearchTarget: { value: "", label: "" },
  fundamentalSearchAcquire: { value: "", label: "" },
  tickerTarget: "",
  tickerAcquire: "",
  nameTarget: "",
  nameAcquire: "",
  sectorTarget: "",
  sectorAcquire: "",
  headquatersTarget: "",
  headquatersAcquire: "",
  categoryId: undefined,
  dateAnnounced: "",
};

export default function AddEditMnaMiniForm({
  isMenuOpen,
  setIsMenuOpen,
}: HandleMenuProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [sectorList, setSectorList] = useState<any>([]);
  const [categoryList, setCategoryList] = useState<any>([]);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const datePickerRef = useRef<ReactDatePicker | null>(null);

  const navigate = useNavigate();

  const navigateDashboard = () => {
    navigate("/mnas");
  };

  const fetchOptionsWithLoading = (query: any) => {
    return fetchFundamentalByQuery(query, setLoading);
  };

  useEffect(() => {
    fetchSectorlist(setSectorList);
    fetchCategorylist(setCategoryList);
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setStatus, resetForm }) => {
      const mnaToCreate = {
        categoryId: Number(values.categoryId),
        dateAnnounced: convertToDateFormat(values.dateAnnounced, "YYYY-MM-DD"),
        target: {
          id: values.fundamentalSearchTarget.value || "",
          name: values.nameTarget || "",
          ticker: values.tickerTarget || "",
          sectorId: Number(values.sectorTarget),
          headquarters: values.headquatersTarget || "",
        },
        acquirer: {
          id: values.fundamentalSearchAcquire.value || "",
          name: values.nameAcquire || "",
          ticker: values.tickerAcquire || "",
          sectorId: Number(values.sectorAcquire),
          headquarters: values.headquatersAcquire || "",
        },
      };
      try {
        setLoading(true);
        await createMnaView(mnaToCreate);
        setStatus({ success: "MNA created successfully!" });
        resetForm();
        navigateDashboard();
        setLoading(false);
        showAlert({
          message: 'MNA created successfully!',
          type: 'success'
        })
      } catch (error) {
        handleFormError(error);
      }
    },
  });

  const handleDateChange = (date: Date | null) => {
    const formattedDate = date ? convertToDateFormat(date, "MM/DD/YYYY") : "";
    formik.setFieldValue("dateAnnounced", formattedDate);
    setSelectedDate(date);
  };

  useEffect(() => {
    const updateTargetFields = async () => {
      if (formik.values.fundamentalSearchTarget?.value) {
        try {
          const fundamentalTarget = await getFundamentalById(
            formik.values.fundamentalSearchTarget.value
          );
          formik.setFieldValue("tickerTarget", fundamentalTarget.ticker);
          formik.setFieldValue("nameTarget", fundamentalTarget.name);
          formik.setFieldValue("sectorTarget", fundamentalTarget.sectorId);
          formik.setFieldValue("headquatersTarget", fundamentalTarget.hq);
        } catch (error) {
          console.error("Error fetching fundamental target:", error);
        }
      } else {
        formik.setFieldValue("tickerTarget", "");
        formik.setFieldValue("nameTarget", "");
        formik.setFieldValue("sectorTarget", "");
        formik.setFieldValue("headquatersTarget", "");
      }
    };
    updateTargetFields();
  }, [formik.values.fundamentalSearchTarget?.value]);

  useEffect(() => {
    const updateAcquireFields = async () => {
      if (formik.values.fundamentalSearchAcquire?.value) {
        try {
          const fundamentalAcquirer = await getFundamentalById(
            formik.values.fundamentalSearchAcquire.value
          );
          formik.setFieldValue("tickerAcquire", fundamentalAcquirer.ticker);
          formik.setFieldValue("nameAcquire", fundamentalAcquirer.name);
          formik.setFieldValue("sectorAcquire", fundamentalAcquirer.sectorId);
          formik.setFieldValue("headquatersAcquire", fundamentalAcquirer.hq);
        } catch (error) {
          console.error("Error fetching fundamental acquirer:", error);
        }
      } else {
        formik.setFieldValue("tickerAcquire", "");
        formik.setFieldValue("nameAcquire", "");
        formik.setFieldValue("sectorAcquire", "");
        formik.setFieldValue("headquatersAcquire", "");
      }
    };
    updateAcquireFields();
  }, [formik.values.fundamentalSearchAcquire?.value]);

  return (
    <React.Fragment>
      <form
        onSubmit={formik.handleSubmit}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <div
          className={`py-[25px] pr-[25px] pb-[60px] duration-500 h-full ${
            isMenuOpen ? "pl-[310px]" : "pl-[85px]"
          } `}
        >
          <div className="-ml-4 mb-4">
          <Breadcrumbs breadcrumbs={["Create MNA"]} />
          </div>
          <div className="bg-[#fff] border border-[#EEEEEE] rounded-md h-full pt-[36px] pl-[39px] pr-[48px]">
            <div className="grid grid-cols-[3fr_3fr] gap-x-4 ">
              <div className=" pb-2 font-semibold text-base">
                Date announced
              </div>
              <div className="pb-2 font-semibold text-base">Category</div>
              <div className="relative pb-2 font-semibold text-base h-12">
                <DatePickerMna
                  selectedDate={selectedDate}
                  handleDateChange={handleDateChange}
                  formik={formik}
                  datePickerRef={datePickerRef}
                />
              </div>
              <CategorySelectMna formik={formik} categoryList={categoryList} />{" "}
            </div>
            <div className="grid grid-cols-[2fr_3fr_3fr] gap-4 mt-[37px]">
              <div className="p-2 h-12"></div>
              <div className="py-2 font-semibold text-base h-12">Target</div>
              <div className="py-2 font-semibold text-base h-12">Acquire</div>
              <div className="py-2 font-semibold text-base h-12">
                Fundamental Search
              </div>
              <div className="border border-[#eeeeee] rounded-md h-12">
                <AsyncSelect
                  id={"fundamentalSearchTarget"}
                  name={"fundamentalSearchTarget"}
                  placeholder="Search Fundamentals..."
                  data-testid="target-async"
                  fetchOptions={fetchOptionsWithLoading}
                  value={formik.values.fundamentalSearchTarget}
                  formik={formik}
                  loading={loading}
                  error={""}
                  noOptionsMessage="No company found"
                  onkeydown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      fetchFundamentalByQuery(e?.target?.value, setLoading);
                    } else {
                      formik.setValues({
                        ...formik.values,
                        fundamentalSearchTarget: e.target.value,
                      });
                    }
                  }}
                />
              </div>
              <div className="border border-[#eeeeee] rounded-md h-12">
                <AsyncSelect
                  id={"fundamentalSearchAcquire"}
                  name={"fundamentalSearchAcquire"}
                  placeholder="Search Fundamentals..."
                  data-testid="acquirer"
                  fetchOptions={fetchOptionsWithLoading}
                  value={formik.values.fundamentalSearchAcquire}
                  formik={formik}
                  loading={loading}
                  error={""}
                  noOptionsMessage="No company found"
                  onkeydown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      fetchFundamentalByQuery(e?.target?.value, setLoading);
                    } else {
                      formik.setValues({
                        ...formik.values,
                        fundamentalSearchAcquire: e.target.value,
                      });
                    }
                  }}
                />
              </div>
              <div className=" py-2 font-semibold text-base">Ticker</div>
              <div className="border border-[#eeeeee] rounded-md overflow-hidden h-12">
                <InputField
                  id="tickerTarget"
                  name="tickerTarget"
                  value={formik.values.tickerTarget}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={""}
                />
              </div>
              <div className="border border-[#eeeeee] rounded-md overflow-hidden h-12">
                <InputField
                  id="tickerAcquire"
                  name="tickerAcquire"
                  value={formik.values.tickerAcquire}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={""}
                />
              </div>
              <div className=" py-2 font-semibold text-base">Name</div>
              <div
                id="nameTargetContainer"
                className={`border border-[#eeeeee] rounded-md overflow-hidden h-12 ${
                  formik.errors.nameTarget &&
                  formik.touched.fundamentalSearchTarget?.value &&
                  "border border-[#ff6464]"
                }`}
              >
                <InputField
                  id="nameTarget"
                  name="nameTarget"
                  value={formik.values.nameTarget}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={""}
                />
              </div>
              <div
                id="nameAcquireContainer"
                className={`border border-[#eeeeee] rounded-md overflow-hidden h-12 ${
                  formik.errors.nameAcquire &&
                  formik.touched.fundamentalSearchTarget?.value &&
                  "border border-[#ff6464]"
                }`}
              >
                <InputField
                  id="nameAcquire"
                  name="nameAcquire"
                  value={formik.values.nameAcquire}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={""}
                />
              </div>
              <div className=" py-2 font-semibold text-base">Sector</div>
              <div className="border border-[#eeeeee] rounded-md overflow-hidden h-12">
                <div className="relative w-full">
                  <SectorSelectMna
                    id="sectorAcquire"
                    name="sectorAcquire"
                    value={formik.values.sectorAcquire}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    sectorList={sectorList}
                  />
                </div>
              </div>
              <div className="border border-[#eeeeee] rounded-md overflow-hidden h-12">
                <div className="relative w-full">
                  <SectorSelectMna
                    id="sectorTarget"
                    name="sectorTarget"
                    value={formik.values.sectorTarget}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    sectorList={sectorList}
                  />
                </div>
              </div>
              <div className=" py-2 font-semibold text-base">Headquarters</div>
              <div className="border border-[#eeeeee] rounded-md overflow-hidden h-12">
                <InputField
                  id="headquatersTarget"
                  name="headquatersTarget"
                  value={formik.values.headquatersTarget}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={""}
                />
              </div>

              <div className="border border-[#eeeeee] rounded-md overflow-hidden h-12">
                <InputField
                  id="headquatersAcquire"
                  name="headquatersAcquire"
                  value={formik.values.headquatersAcquire}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={""}
                />
              </div>
            </div>
            <div className="mt-[37px] gap-2.5 flex h-12 justify-end mb-[305px]">
            <CancelButtonMna onClick={navigateDashboard} />
            <SaveButtonMna type="submit" />
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
}
