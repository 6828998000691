// React
import React from "react";
// Images
import Arrow from "../../../../src/assets/svg/arrow-down-select.svg";

interface CategorySelectMnaProps {
  formik: any;
  categoryList: any[];
}

const CategorySelectMna: React.FC<CategorySelectMnaProps> = ({ formik, categoryList }) => {
  return (
    <div
    id="categorySelectContainer"
      className={`rounded-md overflow-hidden h-12 ${
        formik.errors.categoryId && formik.touched.categoryId ? "border border-[#ff6464]" : ""
      }`}
    >
      <div className="relative w-full">
        <select
          id="categoryIdMna"
          name="categoryId"
          className="dark:text-white dark:placeholder:text-white dark:bg-primary-dark px-[15px] text-base text-[#7B7B7B] font-light w-full h-12 outline-none"
          value={formik.values.categoryId}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          style={{
            appearance: "none",
            backgroundColor: "white",
            color: "#667185",
            border: "1px solid #EEEEEE",
            borderRadius: "0.375rem",
            paddingLeft: "15px",
            paddingRight: "35px",
            fontSize: "1rem",
            fontWeight: 300,
            width: "100%",
            height: "3rem",
            outline: "none",
            transition: "border-color 0.3s ease-in-out",
            backgroundImage: `url(${Arrow})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "calc(100% - 20px) center",
            backgroundSize: "16px 16px",
          }}
        >
          <option value="">Category</option>
          {categoryList.map((item, index) => (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default CategorySelectMna;
