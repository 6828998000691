// React
import React, { useCallback, useState } from "react";
// Third party
import { useNavigate } from "react-router-dom";
// Components
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { Columns } from "../../components/Columns/Columns";
import Table from "../Fundamental/components/MnaFundamentalTable";
import DeleteModal from "../../components/DeleteModal";
// Types
import { Sort, HandleMenuProps } from "../../interfaces/pages/variedInterfaces";
// Images
import errorLogIcon from "../../assets/svg/blue-admin.svg";
// Services
import { deleteErrorLogById, fetchErrorLogDomainApi } from "./services/ErrorLogService";

const ErrorList: React.FC<HandleMenuProps> = ({
  isMenuOpen,
  setIsMenuOpen,
}) => {
  const [search] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteID, setDeleteID] = useState<any>();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deletedItemID, setDeletedItemID] = useState<boolean>(false);
  let update = true;

  const fetchErrorList = useCallback(
    async (pageIndex: number, pageSize: number, sortBy: any) => {
      if (update) {
        setLoading(true);
        try {
          return await fetchErrorLogDomainApi(pageSize, pageIndex, sortBy);
        } finally {
          setLoading(false);
          if (deleteID) {
            update = false;
          }
        }
      }
    },
    [deletedItemID]
  );

  const handleDeleteError = async () => {
    try {
      await deleteErrorLogById(String(deleteID));
      update = true;
      setDeletedItemID(!deletedItemID);
      setDeleteID("");
    } finally {
      setDeleteModal(false);
    }
  };

  const columnsInstance = new Columns();

  return (
    <React.Fragment>
        <div
           className={`py-4  flex flex-col ${
            isMenuOpen ? "pl-[282px]" : "pl-[60px]"
          } duration-500`}
      >
        <Breadcrumbs
          breadcrumbs={["Error Logs"]}
          icon={errorLogIcon}
          addItem={() => navigate("/add-mna")}
        // onFilter={(value) => setSearch(value)}
        />
        <div className="mt-[16px]">
          <Table
                      isMenuOpen={isMenuOpen}
            columns={columnsInstance.ErrorList(
              navigate,
              deleteModal,
              setDeleteModal,
              setDeleteID,
            )
            
          }
            fetchData={fetchErrorList}
            search={search}
            loading={loading}
            typeForPagination="setErrorsListPageIndex"
            
          />
        </div>
        <DeleteModal
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          onDeleteItem={handleDeleteError}
        />
      </div>
    </React.Fragment>
  );
};

export default ErrorList;
